<template>
  <div>
    <div class="flex min-h-full items-center justify-center pt-0 md:pt-0 ">
      <div class="flex flex-col md:flex-row w-full">
        <div class="md:w-1/2 bg-image">
          <!-- <HomePageBanner /> -->

        </div>
        <div class="md:w-1/2 flex items-center justify-center bg-gray-100">
          <div v-if="!authenticated">
          <LoginForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageBanner from '../components/HomeBanner.vue'
import LoginForm from '../components/LoginForm.vue'
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'LoginPage',
  components: {
    HomePageBanner,
    LoginForm
  },
  computed: {
    ...mapState(['authenticated', 'admin']),
    linkClass() {
      return `${this.isDarkMode ? 'text-gray-300 hover:bg-gray-300 hover:text-white' : 'text-gray-900 hover:bg-gray-300 hover:text-white'} px-3 py-2 rounded-md text-sm font-medium`;
    }
  },
}
</script>


<style scoped>
body {
  overflow: hidden; /* Hide scrollbars */
}
/* Add any additional styling here */
.bg-image {
  background-image: url('../assets/home_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 50vw; /* Full width */
  height: 100vh; /* Full height */
  position: relative; /* Fixed position */
  bottom: 10; /* Stay at the top */
  left: 0; /* Stay at the left */
  z-index:-1;
}

</style>
