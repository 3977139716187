import store from '../store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AdminPortalView from "../views/AdminPortalView.vue"
import AdminAddUser from "../views/AdminUserAddView.vue"
import PortalView from "../views/PortalView.vue"
import AuctionView from "../views/AuctionView.vue"
import PurchaseView from "../views/PurchaseView.vue"
import ProfileView from "../views/ProfilePage.vue"
import ProductView from "../views/ProductView.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPortalView,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  },
  {
    path: '/portal',
    name: 'Portal',
    component: PortalView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/products',
    name: 'Product',
    component: ProductView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/purchases',
    name: 'Purchases',
    component: PurchaseView,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/auction',
    name: 'Auction',
    component: AuctionView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: {
      requireLogin: true
    }
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.authenticated) {
    next({ name: 'login', query: { to: "portal" } });
  } else if (to.matched.some(record => record.meta.requireAdmin)) {
    if (!store.state.authenticated) {
      next({ name: 'home' });
    } else if (!store.state.admin) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router
