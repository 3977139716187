import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      authenticated : false,
      admin: false,
    };
  },
  mutations: {
    setAuthenticated(state, value) {
      state.authenticated  = value;
    },
    setAdmin(state, value) {
      state.admin  = value;
    },
  },
});

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
