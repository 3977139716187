<template>
  <footer class="fixed bottom-0 w-full bg-gray-900 text-white py-2  text-xs">
    <div class="container mx-auto flex justify-between items-center">
      <div>
        <p class="ml-5 text-xs">&copy; <span id="currentYear"></span> Floribid. All rights reserved.</p>
      </div>
      <!-- <div>
        <a href="#" class="text-gray-400 hover:text-white mr-4">Privacy Policy</a>
        <a href="#" class="text-gray-400 text-xs hover:text-white">Terms of Service</a>
      </div> -->
    </div>
  </footer>
</template>

 <script>
export default {
  name: 'Footer',
  mounted() {
    const currentYear = new Date().getFullYear();
    document.getElementById("currentYear").textContent = currentYear;
  }
}
</script>

