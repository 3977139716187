<template>
  <div class="pt-24 grid grid-cols-1 gap-6 rounded-lg">
    <div class="bg-transparent border border-gray-300 p-4 rounded-lg relative hover:bg-gray-100 transition duration-300" @click="openModal('Add user')">
      <h2 class="text-lg font-bold mb-2">Add user(s)</h2>
      <p class="text-gray-700">Add a new auction user with the verified accounting id and e-mail address.</p>
      <button class="absolute right-0 top-0 h-full w-12 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
    <div class="bg-transparent border border-gray-300 p-4 rounded-lg relative hover:bg-gray-100 transition duration-300" @click="openModal('Add auction')">
      <h2 class="text-lg font-bold mb-2">Add auction(s)</h2>
      <p class="text-gray-700">Add a new auction to the system to be visible for the selected users.</p>
      <button class="absolute right-0 top-0 h-full w-12 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
 
  
<div class="fixed z-10 inset-0 overflow-y-auto" v-show="showModal">
    <div class="flex items-center justify-center min-h-screen px-4">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
        <div class="flex justify-between p-3 bg-gray-200">
          <h2 class="text-xl font-semibold">{{ clickedBox }}</h2>
          <button class="text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out focus:outline-none" @click="closeModal">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18.293 17.707a1 1 0 01-1.414 0L10 11.414l-6.293 6.293a1 1 0 11-1.414-1.414L8.586 10 2.293 3.707a1 1 0 111.414-1.414L10 8.586l6.293-6.293a1 1 0 111.414 1.414L11.414 10l6.879 6.879z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div class="flex justify-between p-6 h-full">
          <div class="w-1/2 pr-3">
            <!-- <div class="bg-transparent border border-gray-300 p-4 rounded-lg relative hover:bg-gray-100 transition duration-30"> -->
              <!-- <h3 class="text-lg font-semibold mb-4">Upload .csv file</h3>
              <p class="text-gray-700">Click here to upload a csv file.</p> -->
                 <div class="col-span-full">
           
            <div class=" flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4 hover:bg-gray-100 transition duration-30 h-full">
              <div class="text-center">
                <FolderIcon class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                  <label for="file-upload" class="relative cursor-pointer rounded-md bg-transparent font-semibold text-gray-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                    <span>Upload a .csv file</span>
                    <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                  </label>
                </div>
              </div>
            </div>
          </div>
            <!-- </div> -->
          </div>
          <div class="w-1/2 pl-3 h-full">
              <div v-if="clickedBox === 'Add user'" :to="{ name: 'addUser' }">
       <router-link to="/admin/adduser">
          <div class="bg-transparent border border-gray-300 p-4 rounded-lg relative hover:bg-gray-100 transition duration-30 h-full">
              <h3 class="text-lg font-semibold mb-3">Manual entry</h3>
              <p class="text-gray-700">Click here to manually fillout a form.</p>
            </div>
       </router-link>
    </div>
    <div v-else-if="clickedBox === 'Add auction'" :to="{ name: 'addAuction' }">
    <!-- <router-link to="/admin/addauction">Add Auction</router-link> -->
       <div class="bg-transparent border border-gray-300 p-4 rounded-lg relative hover:bg-gray-100 transition duration-30 h-full">
              <h3 class="text-lg font-semibold mb-3">Manual entry</h3>
              <p class="text-gray-700">Click here to manually fillout a form.</p>
            </div>
    </div>
         
          </div>
        </div>
      </div>
    </div>
   </div>
  </div>
</template>


<script>
import { FolderIcon, UserCircleIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'AdminActions',
   components: {FolderIcon,},
  data() {
    return {
      showModal: false,
      clickedBox: '',
    }
  },
  methods: {
    openModal(box) {
      this.clickedBox = box
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
  },
}
</script>

<style scoped>
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.2s ease;
}
.modal-enter-from, .modal-leave-to {
  opacity: 0;
}
</style>