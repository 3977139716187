<template> 
<div class="min-h-screen bg-gray-100 p-6">

  <div class="md:flex-row mx-auto px-8 h-full max-w-screen-xl">
    <div class="bg-white rounded-lg shadow-md h-full flex items-center flex-col p-6">
        <div class="w-full p-4 mb-2 border-b border-gray-200 flex items-center">
            <div class="w-1/4 p-4 overflow-hidden h-full pr-4 mt-8">
                <button @click="goBack" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4">
                  <i style="font-size:16px" class="fa mr-2 text-gray-800"> &#xf104;</i> 
                    <span>Return to portal</span>
                </button>
            </div>
            <div class="w-3/4 p-4 overflow-hidden h-full mx-8 px-4">
                <ul class="flex text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                  <ul class="flex  text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        
        <li class="mr-2" @click="activeTab = 'tab1'">
    <a href="#" 
    class="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group text-lg" 
    :class="{ 'inline-flex p-4 text-black font-bold border-b-2 border-black rounded-t-lg active dark:text-black dark:border-black group': activeTab === 'tab1' }"
    aria-current="page">
   
    <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500">&#xf2bd;</i>  User overview 
  
    </a>
</li>

        <li class="mr-2" @click="activeTab = 'tab2'">
    <a href="#" 
    class="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group text-lg" 
    :class="{ 'inline-flex p-4 text-black font-bold border-b-2 border-black rounded-t-lg active dark:text-black dark:border-black group': activeTab === 'tab2' }"
    aria-current="page">
   
    <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500">&#xf0c9;</i>  Auction list overview 
  
    </a>
</li>
        <!-- <li class="mr-2" @click="activeTab = 'tab3'">
    <a href="#" 
    class="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group text-lg" 
    :class="{ 'inline-flex p-4 text-black font-bold border-b-2 border-black rounded-t-lg active dark:text-black dark:border-black group': activeTab === 'tab3' }"
    aria-current="page">
   
    <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-300">&#xf013;</i>  Settings 
  
    </a>
</li> -->

      </ul>
                </ul>
            </div>
        </div>
        <div   class="flex w-full h-500px mt-4 justify-center">

          <div  v-if="activeTab === 'tab2'" class="flex flex-col items-center w-full">
         

            <div class="mb-10 flex justify-center  w-full p-4">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th class="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Seller</th>
                  <th class="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th class="px-6 py-4 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="auction in auctions" :key="auction.id" class="hover:bg-gray-50">

                  <td class="px-6 py-4 text-sm text-gray-900">{{ auction.name }}</td>
            <td class="px-6 py-4 text-sm text-gray-900">{{ auction.seller }}</td>
            <td class="px-6 py-4 text-sm text-gray-900">{{ formattedDate(auction.date) }}</td>
            <td class="px-6 py-4 text-right text-sm">
                <div class="relative inline-block text-left">
                  <button @click="toggleDropdown(auction.id)" class="flex items-center text-gray-500 hover:text-blue-500 hover:underline focus:outline-none">
                    <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500">&#xf0c9;</i>
                  </button>
                  <div v-if="isDropdownOpen(auction.id)" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <a :href="`https://flowers.nu/api/v1/download_auction_purchases/${auction.id}/`" class="flex items-center px-4 py-2 text-gray-500 hover:text-blue-500 hover:underline focus:outline-none w-full text-left">
                        <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500 hover:text-blue-500 hover:underline">&#xf019;</i>
                        Download CSV
                      </a>
                      <router-link :to="'/products?id=' + auction.id" class="flex items-center px-4 py-2 text-gray-500 hover:text-blue-500 hover:underline focus:outline-none w-full text-left">
                        <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500 hover:text-blue-500 hover:underline">&#xf07a;</i>
                        Products
                      </router-link>
                      <button @click="deleteAuction(auction.id)" class="flex items-center px-4 py-2 text-gray-500 hover:text-blue-500 hover:underline focus:outline-none w-full text-left">
                        <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500">&#xf014;</i>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </td>
<!--               
                  <td class="px-4 py-2 text-right text-sm">
                   
                    <a :href="`https://flowers.nu/api/v1/download_auction_purchases/${auction.id}/`" class="flex items-center px-4 py-2 text-gray-500 hover:text-gray-700 focus:outline-none w-full text-left">
        <i style="font-size:16px" class="fa mr-2 mt-1 text-gray-500 hover:text-blue-500 ">&#xf019;</i> </a>
      
        <router-link class="text-sm text-blue-500 hover:underline" :to="'/products?id=' + auction.id"> Products </router-link>
        
        <a href="#" @click="deleteAuction(auction.id)"  class="fa mr-2 mt-1 text-sm text-blue-500  ">Delete &#xf014;</a>


                  </td> -->
                </tr>
                <tr  @click="auctionModal = true" class="bg-green-100">
      <td colspan="4" class="border px-4 py-2  text-center">
        <button @click="auctionModal = true" class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">
          Add Auction +
        </button>
      </td>
    </tr>
              </tbody>
            </table>
          </div>
        </div>
          
            <div v-if="activeTab === 'tab1'" class="w-1/4 p-4 overflow-y-auto border-r border-gray-200 h-full pr-4">
              <div >
                    <h2 class="text-lg font-medium border-b border-gray-200 mb-2 text-gray-900">Current users</h2>
                    <ul>
                      <li class="bg-green-100 hover:bg-green-300" @click="toggleUserSelection(null)">
                        <h3 class="text-sm font-semibold py-4 "> Add new user + </h3>
                      </li>
                        <li v-for="user in userList" :key="user.id" @click="toggleUserSelection(user)" :class="{'bg-gray-200': selectedUser && selectedUser.id === user.id}" class="cursor-pointer   rounded hover:bg-gray-100">
                            <div class="text-center  ">
                                <h3 class="text-center py-2 border-t border-gray-200 text-xs font-medium text-gray-500 uppercase">{{ user.username }}</h3>
                                <!-- <p class="text-sm text-gray-500">{{ user.email }}</p> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="activeTab === 'tab1'" class="w-3/4 p-4 h-500px overflow-hidden">
                <!-- Content -->
                <div>

                
          <div class="w-1/2 mx-2 ">
     
          
          <div class="pt-4 flex-grow overflow-y-auto no-scrollbar">
            <div class="mb-4">

              <h2  class="text-m font-semibold border-b border-gray-200 py-2" v-if="selectedUser">Update {{ selectedUser.username }} </h2>
              <h2 class="text-m font-semibold border-b border-gray-200 py-2"   v-else> Add user </h2>
              

              <label class="text-sm  text-gray-700 block mb-1 mt-2">Username</label>

              <input v-if="selectedUser"  type="text" v-model="selectedUser.username"  class="w-full block px-3 py-2 border border-gray-300 rounded-lg bg-white text-black"> 
              <input v-else  type="text"  class="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white text-black"> 
              
            </div>
   
       
            <div class="mb-4">
              <label class="text-sm  text-gray-700 block mb-1">New password</label>
              <input v-model="newPassword" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white text-black">
            </div>
            <div class="mb-4">
              <label class=" text-sm  text-gray-700 block mb-1">Confirm password</label>
              <input v-model="confirmPassword" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white text-black">
            </div>
            <div class="mt-6 flex justify-center">
              <button  v-if="selectedUser"  @click="updatePassword" class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Update</button>
              
              <button  v-if="selectedUser" @click="deleteSelectedUser()" class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600">Delete</button>
              <button  v-else @click="createUser" class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">Create</button>
            </div>
        
                </div>
            </div>
        </div>
        
      </div>
    </div>
  </div>


<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
     <div class="flex flex-row w-full  ">



  <transition name="modal">
    <div class="modal-overlay" v-if="auctionModal">
      <div class="modal">
        <div class="modal-header">
          <h3>Upload Excel File</h3>
          <button @click="auctionModal = false" class="text-2xl font-bold leading-none">&times;</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
<div class="form-group">
  <label for="csv-file" class="block font-medium text-sm text-gray-700 mb-2">Select Excel File:</label>
  <div class="flex items-center justify-center">
    <label for="csv-file" class="cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
      <span v-if="!selectedFile">Choose File</span>
      <span v-if="selectedFile">Selected file: {{ selectedFile.name }}</span>
    </label>
   
  </div>
  <input id="csv-file" type="file" class="hidden" accept=".xlsx" @change="handleFileSelect" />
</div>
            <div class="form-group mt-5">
              <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" :disabled="!selectedFile">Submit</button>
              <button type="button" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" @click="auctionModal = false">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>


</div>
</div>
    </div>
  </div>
 
</template>

<script>
// @ is an alias to /src
import AdminActions from '@/components/AdminGrid.vue'
import AuctionList from '@/components/AuctionList.vue'
import axios from 'axios';

export default {
  name: 'AdminPortalView',
  components: {
    AdminActions,
    AuctionList
  },
   data() {
    return {
      auctions: [],
      events: [],
      selectedEvent: null,
      userList: [],
      activeTab: 'tab1',
      showModal: false,
      username: '',
      password: '',
      email: '',
      auctionModal: false,
      selectedFile: null,
      selectedAuctionProducts: null,
      selectedAuction: null,
      selectedUser: null,
      openDropdownId: null,
      showProductModal: false,      
    };
  },
  mounted() {
    document.title = 'Floribid | Admin Portal', 
     this.fetchAuctions();
     this.fetchUsers();
    },
  methods: {
    
  async createUser() {
      const token = localStorage.getItem('access_token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const formData = {
        username: this.username,
        password: this.password,
        email: this.email
      };
      try {
        const response = await axios.post('/add_user/', formData, config);
        // console.log('User added:', response.data);
        this.showModal = false;
        this.username = '';
        this.password = '';
        this.email = '';

 
        this.$emit('userAdded');
      } catch (error) {
        console.error(error);
      }
      this.showModal = false;
      this.fetchUsers();
      
    },
    
    async deleteUser(userId){
      const token = localStorage.getItem('access_token');
      const config = { headers: { Authorization: `Bearer ${token}` } };

       if (userId === 1 || userId === 2) {
        console.warn('Cannot delete user 1 or 2')
        return
      }

    await axios.delete(`/delete_user/${userId}/`,config)
    .then(response => {
          console.log(response)
          // Perform any additional actions here
        })
        .catch(error => {
          console.error(error)
          // Handle any errors here
        })
    this.fetchUsers();
    },
    deleteSelectedUser(){
      const userID = this.selectedUser.id
      this.deleteUser(userID)
    }
,
    async resetAuction(auctionId){
      const token = localStorage.getItem('access_token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      await axios.post(`/reset_auction/${auctionId}/`,config)
    .then(response => {
          console.log(response)
          // Perform any additional actions here
        })
        .catch(error => {
          console.error(error)
          // Handle any errors here
        })
    this.fetchAuctions();
    },

    async deleteAuction(auctionId){
      const token = localStorage.getItem('access_token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      await axios.delete(`/delete_auction/${auctionId}/`,config)
    .then(response => {
          console.log(response)
          // Perform any additional actions here
        })
        .catch(error => {
          console.error(error)
          // Handle any errors here
        })
    this.fetchAuctions();
    },
    productModal(products) {
      this.showProductModal = true;
      this.selectedAuctionProducts = products
    },

    formatDate(date) {
      return new Intl.DateTimeFormat('en-US').format(new Date(date));
    },
    fetchUsers(){
          const accessToken = localStorage.getItem('access_token');
    axios.get('/get_user_list/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        this.userList = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    },
    async fetchAuctions() {
        const accessToken = localStorage.getItem('access_token');
        try {
        const response = await axios.get('/get_auctions/', {
      headers: {Authorization: `Bearer ${accessToken}`,},});
    this.auctions = response.data;
    // console.log(response.data)
     } catch (error) {
        console.log(error);
      }
    },
    formattedDate(date) {
      const dateObj = new Date(date)
      const options = {
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        second: 'numeric', 
        timeZone: 'UTC'
      }
      return dateObj.toLocaleDateString('en-US', options)
    },

     handleFileSelect(event) {
      this.selectedFile = event.target.files[0];
    },
     async handleSubmit() {
      const formData = new FormData();
      if (!this.selectedFile) {
        alert('Please select a file to upload')
        return
      }
      formData.append('file', this.selectedFile);
      const accessToken = localStorage.getItem('access_token');
      const config = { headers: { Authorization: `Bearer ${accessToken}`,
                                 'Content-Type': 'multipart/form-data', } };

      try {
        const response = await axios.post('/upload_auction_file/', formData, config);
        console.log(response.data);
        this.selectedFile = null;
        this.auctionModal = false;

     }catch (error) {
        console.error(error);
      };
      this.fetchAuctions();
  },
  goBack() {
            this.$router.push('/portal');
        },
  selectAuction(auctionName) {
      this.selectedAuction = auctionName;
    },
    toggleAuctionSelection(auctionName) {
      this.selectedAuction = this.selectedAuction === auctionName ? null : auctionName;
    },
    selectUser(userName) {
      this.selectedUser = userName;
    },
    toggleUserSelection(userName) {
      this.selectedUser = this.selectedUser === userName ? null : userName;
    },
    toggleDropdown(id) {
      this.openDropdownId = this.openDropdownId === id ? null : id;
    },
    isDropdownOpen(id) {
      return this.openDropdownId === id;
    },

  },
   created() {
    this.fetchUsers();
    this.fetchAuctions();
  },
}
</script>

<style scoped> 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  max-width: 600px;
  width: 90%;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.5rem;
}

.modal-body {
  margin-bottom: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-footer button {
  margin-left: 1rem;
}

.delete-button {
  position: relative;
  display: inline-block;
  padding: 0.5em 1em;
  background-color: #f00;
  color: #fff;
  border: none;
  border-radius: 0.25em;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #d00;
}

.cross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  border: 0.1em solid #fff;
}

.cross::before,
.cross::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.4em;
  height: 0.1em;
  background-color: #fff;
}

.cross::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.cross::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.flex {
    display: flex;
}
.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}
.h-full {
    height: 100%;
}
.border-r {
    border-right-width: 1px;
}
.border-gray-200 {
    border-color: #edf2f7;
}
.pr-4 {
    padding-right: 1rem;
}
.w-16 {
    width: 4rem;
}
.h-16 {
    height: 4rem;
}
.object-cover {
    object-fit: cover;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.h-500px {
    height: 500px;
}
.bg-gray-200 {
    background-color: #e2e8f0;
}
.hover\:bg-gray-100:hover {
    background-color: #f7fafc;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.flex-grow {
  flex-grow: 1;
}
.overflow-hidden {
  overflow: hidden;
}

</style>