import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import da from './locales/da.json';
import router from './router'
import store from './store'
import './index.css'
import FlagIcon from 'vue-flag-icon'
import axios from 'axios'

const messages = {
    en: en,
    da: da 
  };

  const i18n = createI18n({
    locale: 'en', // set default locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
  });

axios.defaults.baseURL = 'https://flowers.nu/api/v1'


createApp(App).use(i18n).use(store).use(FlagIcon).use(router).mount('#app')
