<template>
  <div class="flex flex-col">
    <h2 class="font-bold mb-4 pt-10">Upcoming Auctions</h2>
    
        <div class="space-y-4">
      <div v-for="event in events" :key="event.id" class="p-4 border border-gray-200 rounded-lg flex items-center justify-between">
        <h3 class="text-lg font-bold">{{ event.name }} <span class="text-sm text-gray-500 ml-2">Date: {{ formatDate(event.date) }}</span></h3>
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="showModal(event)">
          See Details
        </button>
      </div>
    </div>
    
    

  </div>
</template>

<script>
export default {
  name: 'AuctionList',
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US').format(new Date(date));
    },
    showModal(event) {
      this.$emit('show-modal', event);
    },
  },
};
</script>

