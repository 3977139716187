<template>
<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">

    <Login />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  name: 'LoginView',
  components: {
    Login
  },
    mounted() {
        document.title = 'Floribid | Login'
    },
}
</script>
