<template>
  <div class="min-h-screen p-4">
    <div class="max-w-6xl mx-auto">
      <h1 class="text-2xl font-bold mb-4">Your Purchases</h1>
      <div v-if="isLoading" class="text-center p-4">Loading...</div>
      <div v-if="error" class="text-red-500 text-center p-4">{{ error }}</div>
      <div class="overflow-x-auto mb-10">
        <table class="min-w-full table-auto">
          <thead class="bg-gray-200">
            <tr>
              <th class="px-4 py-2 text-left">Product ID</th>
              <th class="px-4 py-2 text-left">Auction Name</th>
              <th class="px-4 py-2 text-left">Date</th>
              <th class="px-4 py-2 text-left">Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="purchase in purchases" :key="purchase.id" class="border-b">
              <td class="px-4 py-2">{{ purchase.product.id }}</td>
              <td class="px-4 py-2">{{ purchase.auction.name }}</td> 
              <td class="px-4 py-2">{{ new Date(purchase.purchase_time).toLocaleDateString() }}</td>
              <td class="px-4 py-2">{{ purchase.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  name: 'PurchaseView',
  components: {
  
  },
  data() {
    return {
      purchases: [],
      isLoading: false,
      error: null,
    };
  },
  methods: {
    fetchPurchases() {
      this.isLoading = true;
      const accessToken = localStorage.getItem('access_token');
      axios.get('/purchases/', { // Make sure to replace '/purchase_url/' with your actual API endpoint
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        this.purchases = response.data;
      console.log(this.purchases)
      })
      .catch(error => {
        this.error = "Failed to load purchases.";
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.fetchPurchases();
  },
};
</script>