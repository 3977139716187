<template>
    <div class="relative inline-block">
      <div
        :class="['h-4 w-4 ml-2 mr-2 rounded-full cursor-pointer', { 'bg-green-500': isConnected, 'bg-red-500': !isConnected }]"
        @mouseover="handleMouseOver"
        @mouseleave="showTooltip = false"
      ></div>
      <div v-if="showTooltip" class="absolute top-8 left-1/2 -mb-4 opacity-0 transition duration-200 ease-in-out z-50" :class="{ 'opacity-100': showTooltip }" style="transform: translateX(-70%)">
        <div class="bg-black text-white text-xs rounded py-1 px-4">
          {{ isConnected ? 'Connected to the server' : 'Disconnected' }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConnectionIndicator',
    props: {
      isConnected: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        showTooltip: false,
      };
    },
    methods: {
      handleMouseOver() {
        this.showTooltip = true;
        if (this.isMobileDevice()) {
          setTimeout(() => {
            this.showTooltip = false;
          }, 1000);
        }
      },
      isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
    },
  };
  </script>
  