<template>
 <div class="min-h-screen bg-gray-100 p-6">
  <div class="md:flex-row mx-auto px-8 h-full max-w-screen-xl">
    <div class="bg-white rounded-lg shadow-md h-full flex p-6">

   
    <div class="w-full max-w-4xl mx-auto md:mt-8 md:mb-10">
      <div v-if="events.length > 0"  class="relative lg sm:rounded-lg overflow-hidden flex">
        <div class="w-1/2 flex flex-col w-200px justify-center items-center text-center p-4">
          <h3 class="text-xl font-semibold text-black">Next Auction: {{ events[0].name }}</h3>
          <p class="text-md text-gray-800 mt-2">Auction date: {{ formattedDate(events[0].date) }}</p>
          <div class="mt-4">
            <router-link :to="'/auction?id=' + events[0].id" class="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-600 mr-2">Join Auction</router-link>
            <router-link :to="'/products?id=' + events[0].id" class="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-600">View Products</router-link>
          </div>
        </div>
        <div class="w-1/2 relative">
          <img class="w-full h-full object-cover" src="/plants_car.jpg" alt="Auction Image" style="height: 300px;">
          <div class="absolute inset-0 bg-black opacity-60"></div>
          <div class="absolute inset-0 flex flex-col justify-center items-center text-center p-4">
            <p class="text-m text-white">Time until auction starts:</p>
            <p class="text-xl text-white font-semibold">  {{ timeUntilBegins }}</p>
          </div>
        </div>
     
      </div>

      <div  v-else class="relative lg sm:rounded-lg overflow-hidden flex flex-col justify-center items-center text-center p-4">
        <img class="w-full h-full object-cover" src="/plants_car.jpg" alt="Auction Image" style="height: 300px;"> 
        <div class="absolute inset-0 bg-black opacity-60"></div>
        <div class="absolute inset-0 flex flex-col justify-center items-center text-center p-4">
        
        <h3 class="text-xl font-semibold text-white">No upcoming auctions</h3>
      </div>
      </div>
   
  </div>
  
</div>
</div>


<!-- Upcoming Auctions -->
<div class="md:flex-row mx-auto px-8 py-4 h-full max-w-screen-xl">
  <div class="bg-white rounded-lg shadow-md h-full flex p-6">
    <div class="mt-2 w-full">
      
      <h2 class="text-lg font-semibold  text-center">Upcoming Auctions</h2>
      <span class="text-xs text-gray-500 mx-2 mb-4">Click on an auction to view the product list.</span>
      <div class="flex overflow-x-auto no-scrollbar space-x-4 mt-2 items-center">
        <router-link v-for="auction in upcomingAuctions" :key="auction.id"   :to="'/products?id=' + auction.id"  class="relative w-64 h-40 rounded-lg overflow-hidden shadow-md cursor-pointer flex-shrink-0">
          <img src="/plant.jpg" alt="Auction Image" class="w-full h-full object-cover">
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div class="absolute bottom-0 left-0 right-0 p-4 text-white">
            <div class="font-semibold">{{ auction.name }}</div>
            <div class="text-sm">{{ formattedDate(auction.date) }}</div>
            <div class="text-sm">{{ auction.time }}</div>
          </div>
        </router-link>
        <div  class="relative w-64 h-40 rounded-lg overflow-hidden shadow-md cursor-pointer flex-shrink-0">
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div class="absolute bottom-5 left-0 right-0 p-4 text-white">
            <div class="font-semibold">Stay posted </div>
            <div class="font-semibold">for more </div>
            <div class="font-semibold">auctions </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

</div>
</template>

<script>
// @ is an alias to /src
import UpcomingAuction from '@/components/UpcomingAuction.vue'
import AuctionList from '@/components/AuctionList.vue'

import axios from 'axios';

export default {
  name: 'PortalView',
  components: {
    UpcomingAuction,
    AuctionList
  },
   data() {
    return {
      events: [],
      activeTab: 'upcoming', // Automatically select the upcoming tab
      upcomingAuctions: [], // Populate with your upcoming auctions data
      pastAuctions: [], // Populate with your past auctions data
      showEventModal: false,
      selectedEvent: null,
      timeUntilBegins: null,
      nextAuctionLink: null,
      
    };
  },
  mounted() {
    this.fetchAuctions();
    
    document.title = 'Floribid | Portal'

    this.updateTimeUntilBegins();
    // Update the time remaining every second
    setInterval(this.updateTimeUntilBegins, 1000);
  },
  methods: {
    updateTimeUntilBegins() {
      // Calculate the time until the auction begins
      try {
      const now = new Date();
      const begins = new Date(this.events[0].date);
          
      const diff = begins.getTime() - now.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      // Set the time until the auction begins in the data
      this.timeUntilBegins =  `${days}d ${hours % 24}h ${minutes % 60}m ${seconds % 60}s`;
    }
    catch (error) {
        console.log(error);
        this.timeUntilBegins = "N/A"
      }},
    fetchAuctions(){
      const accessToken = localStorage.getItem('access_token');
    axios.get('/user_auction_list/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data && response.data.length > 0) {
    this.events = response.data;
    console.log(this.events)
    this.nextAuctionLink = this.events[0].id;
  } else {}
      })
      .catch(error => {
        console.log(error);
      });
    },
formattedDate(date, subtract15Minutes = false) {
  const dateObj = new Date(date);
  if (subtract15Minutes) {
    dateObj.setMinutes(dateObj.getMinutes() - 15); // subtract 15 minutes
  }
  const options = {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    second: 'numeric', 
    timeZone: 'UTC'
  };
  return dateObj.toLocaleDateString('en-US', options);
},

    
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US').format(new Date(date));
    },
    showModal(event) {
      this.selectedEvent = event;
      this.showEventModal = true;
    },
    hideModal() {
      this.selectedEvent = null;
      this.showEventModal = false;
    },
    organizeAuctions() {
    this.pastAuctions = this.events.filter(event => event.in_progress === 2);
    // Assuming you also want to remove past auctions from `events`
    this.events = this.events.filter(event => event.in_progress !== 2);
  }
  },
  created(){
    this.organizeAuctions();
  },
  computed: {
    upcomingAuctions() {
      this.organizeAuctions();
      const now = new Date();
      // Filter events to get upcoming ones and remove the first item assuming it's shown in the "Next Auction" box
      const filteredEvents = this.events.filter(event => new Date(event.date) > now);
      if (filteredEvents.length > 1) {
        return filteredEvents.slice(1); // Return the list excluding the first event
      }
      return []; // Return an empty array if there's only one upcoming event
    },
    // pastAuctions() {
    //   const pastEvents = this.events.filter((event) => event.in_progress ===  2);

    // // Filter out the transferred auctions from events
    // this.events = this.events.filter(event => event.in_progress !== 2);
    
    // if (pastEvents.length >0){
    //   return pastEvents;
    // }
    //  // Keep past auctions as an empty array for now
    //   return [];
    // },
  },
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
.overflow-hidden {
  overflow: hidden;
}

.justify-center {
  justify-content: center;
}

.overflow-x-auto {
  overflow-x: auto;
}

.flex {
  display: flex;
}


.overflow-x-auto {
  overflow-x: auto;
}


.items-center {
  align-items: center;
}

.space-x-4 > * + * {
  margin-left: 1rem; /* Adjust spacing as needed */
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.w-64 {
  width: 16rem; /* Width of each box */
}

.h-40 {
  height: 10rem; /* Height of each box */
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>