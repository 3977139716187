<template>
  <div class="overflow-hidden mt-10 bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Next auction</h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">Details, timeschedule, and seller information.</p>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div v-if="auctionOpens && auctionBegins" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Auction opens</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ auctionOpens }}</dd>
        </div>
        <div v-if="auctionOpens && auctionBegins" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Auction begins</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ auctionBegins }}</dd>
        </div>
        <div v-if="timeUntilBegins" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Time until auction starts</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ timeUntilBegins }}</dd>
        </div>
        <div class="bg-whitepx-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Seller information</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">N/A</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Product list</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">N/A</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
          <router-link to="/auction?id=1" class="mt-5 ml-1 inline-block rounded-md border border-transparent bg-gray-500 py-3 px-8 text-center font-medium text-white hover:bg-gray-800">Join auction</router-link> 
          </div>
      </dl>
    </div>
  </div>
</template>


<script>
export default {
    name: 'UpcomingAuction',
  data() {
    return {
      auctionOpens: '2023-04-01 08:45:00',
      auctionBegins: '2023-04-01 09:00:00',
      timeUntilBegins: null,
      auctionId: 13,
    };
  },
  mounted() {
    this.updateTimeUntilBegins();
    // Update the time remaining every second
    setInterval(this.updateTimeUntilBegins, 1000);
        
  },
  methods: {
    updateTimeUntilBegins() {
      // Calculate the time until the auction begins
      const now = new Date();
      const begins = new Date(this.auctionBegins);
      const diff = begins.getTime() - now.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      // Set the time until the auction begins in the data
      this.timeUntilBegins = `${days}d ${hours % 24}h ${minutes % 60}m ${seconds % 60}s`;
    }
  }
  }

</script>