<template>
    <div class="min-h-screen bg-gray-100 p-6">
        <div class="md:flex-row mx-auto px-8 h-full max-w-screen-xl">
            <div class="bg-white rounded-lg shadow-md h-full flex p-6 flex-col">

                <!-- Auction Information Row -->
               
                <div class="w-full p-4 mb-2 border-b border-gray-200 flex items-center">
                    <div class="w-1/4 p-4 overflow-hidden  h-full pr-4">
                    <button @click="goBack" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4">
                        <i style="font-size:16px" class="fa mr-2 text-gray-800"> &#xf104;</i> 
                          <span>Return to portal</span>
                    </button>
                    </div>
                    <div class="w-3/4 p-4 overflow-hidden h-full pr-4">
                    
                    <div>
                        <h2 class="text-lg font-bold">{{ auction.name }}</h2>
                        <p>Date: {{ formattedDate(auction.date) }}</p>
                        <p>Seller: {{ auction.seller }}</p>
                    </div>
                </div>
            </div>

                <!-- Product List and Details Section -->
                <div class="flex w-full h-500px">
                    <div class="w-1/4 p-4 overflow-y-auto border-r border-gray-200 h-full pr-4">
                       <div class="border-b border-gray-200 mb-2">
                        <h2 class="text-medium font-bold pb-2">Product list</h2>
                        
                        <div>
                            <input type="radio" id="showAll" value="Show All" v-model="showOption">
                            <label class="text-xs text-gray-500 mx-2" for="showAll">Show All</label>
                            <input type="radio" id="showLiked" value="Show Liked" v-model="showOption">
                            <label class="text-xs text-gray-500 mx-2" for="showLiked">Liked only</label>
                        </div>
                    </div>
                        <ul>
                            <li v-for="product in productsToShow" :key="product.id" @click="toggleProduct(product)" :class="{'bg-gray-200': selectedProduct && selectedProduct.id === product.id}" class="cursor-pointer mb-4 p-2 rounded hover:bg-gray-100">
                                <img :src="product.url" alt="Product Image" class="w-16 h-16 object-cover mb-2 mx-auto">
                                <div class="text-center">
                                    <h3 class="text-sm font-semibold">{{ product.name }}</h3>
                                    <p class="text-sm text-gray-500">{{ product.start_price }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="w-3/4 p-4 h-500px overflow-hidden">
                        <div v-if="selectedProduct" class="p-4 rounded-lg flex">
                            <div class="relative w-1/2">
                                <img :src="selectedProduct.url" alt="Selected Product Image" class="w-full h-auto mb-4">
                                <button @click="toggleLike(selectedProduct)" class="absolute bottom-6 right-3 bg-white p-2 rounded-full shadow-md">
                                    <svg class="w-6 h-6" :class="{'text-red-500': selectedProduct.liked, 'text-gray-500': !selectedProduct.liked}" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="w-1/2 pl-4">
                                <div class="border-b border-gray-200 mb-2">
                                <h2 class="text-2xl text-left font-bold mb-2">{{ selectedProduct.name }}</h2>
                                <!-- <p class="mb-2">Minimum Number of Pieces: {{ selectedProduct.minimumPieces }}</p>
                                <p class="mb-2">Quality: {{ selectedProduct.quality }}</p>
                                <p class="mb-2">Starting Price: {{ selectedProduct.startingPrice }}</p>
                                <p class="mb-2">Minimum Price: {{ selectedProduct.minimumPrice }}</p> -->

                                <div class="text-m text-left ml-2">
                                <p><strong>Grower / seller:</strong> {{ auction.seller }}</p>
                                <p><strong>Length:</strong> {{ selectedProduct.length }}</p>
                                <p><strong>Gram (Weight):</strong>  {{ selectedProduct.weight }} </p>
                                <p><strong>Quality:</strong> {{ selectedProduct.quality }} </p>
                                <p><strong>Stems per unit:</strong> {{ selectedProduct.stems_per_unit }} </p>
                                <p><strong>Quantity in box:</strong> {{ selectedProduct.quantity_in_box }} </p>
                                <p><strong>Start price:</strong> {{ selectedProduct.start_price }} </p>
                                <p><strong>Minimum price:</strong>  {{ selectedProduct.minimum_price }} </p>
                                </div>

                            </div>
                                <!-- <div>
                                <label for="autoBidPrice" class="block text-sm font-medium text-gray-700">Auto Bidding Price</label>
                                <input type="number" id="autoBidPrice" v-model="autoBidPrice" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                            </div> -->
                        </div>
                        </div>
                        <div v-else>
                            <p class="text-center text-gray-500">Select a product to view details. You can inspect, like, and set auto-bidding prices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ProductView',
    data() {
        return {
            products: null,
            selectedProduct: null,
            autoBidPrice: null,
            showOption: 'Show All',
            auction: null,
        };
    },
    computed: {
        productsToShow() {
            return this.showOption === 'Show Liked' ? this.products.filter(product => product.liked) : this.products;
        }
    },
    methods: {
        formattedDate(date) {
      const dateObj = new Date(date)
      const options = {
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        second: 'numeric', 
        timeZone: 'UTC'
      }
      return dateObj.toLocaleDateString('en-US', options)
    },

        async fetchAuctionDetails(auctionId) {
    const accessToken = localStorage.getItem('access_token');

    try {
        const response = await axios.get(`/get_auctions/${auctionId}/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        // Handle response data
        // Assuming you have a property `auction` to store details
        this.auction = response.data;
        this.products = response.data.products;
    } catch (error) {
        console.error('Error fetching auction details:', error);
    }
},
        toggleProduct(product) {
            if (this.selectedProduct && this.selectedProduct.id === product.id) {
                this.selectedProduct = null;
                this.autoBidPrice = null;
            } else {
                this.selectedProduct = product;
                this.autoBidPrice = null;
            }
        },
         toggleLike(product) {
             product.liked = !product.liked;
         },
        // async toggleLike(product) {
        //    const accessToken = localStorage.getItem('access_token');
        //    try {
                // Perform like/unlike action
        //         const response = await axios.post(`/products/like/${product.id}/`, {}, {
        //             headers: { Authorization: `Bearer ${accessToken}` },
        //         });
        //         // Update the product's like status
        //         product.liked = response.data.liked;
        //     } catch (error) {
        //         console.error('Error toggling like status:', error);
        //     }
        // },
        goBack() {
            this.$router.push('/portal');
        }
    },
    mounted() {
        const query = new URLSearchParams(window.location.search);
        const auctionId = query.get('id');
        this.fetchAuctionDetails(auctionId);
    }
};
</script>

<style scoped>
.flex {
    display: flex;
}
.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}
.h-full {
    height: 100%;
}
.border-r {
    border-right-width: 1px;
}
.border-gray-200 {
    border-color: #edf2f7;
}
.pr-4 {
    padding-right: 1rem;
}
.w-16 {
    width: 4rem;
}
.h-16 {
    height: 4rem;
}
.object-cover {
    object-fit: cover;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.h-500px {
    height: 500px;
}
.bg-gray-200 {
    background-color: #e2e8f0;
}
.hover\:bg-gray-100:hover {
    background-color: #f7fafc;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.flex-grow {
  flex-grow: 1;
}
.overflow-hidden {
  overflow: hidden;
}


</style>
