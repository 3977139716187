<template>
<div v-if="isLaptopAndFullScreen">
 <Navbar />
 

    <router-view />
<Footer />
</div>
<div v-else>
  <h1>Floribid is only available in a fullscreen laptop browser window.</h1>
</div>

</template>

 
<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { isLaptopAndFullScreen } from '@/js/deviceUtils';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      isLaptopAndFullScreen: false,
    };
  },
  created() {
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  methods : {
    checkDevice() {
      this.isLaptopAndFullScreen = isLaptopAndFullScreen();
  }
}
}

</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}


</style>