<template>
    <div class="relative">
      <button
        class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 rounded-md text-sm font-medium flex items-center"
        @click="toggleDropdown"
        @mouseleave="closeDropdownDelayed"
      >
        <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <div
        class="absolute right-0 mt-0 w-48 bg-white rounded-md shadow-lg z-10 text-center"
        v-show="showDropdown"
        @mouseenter="cancelCloseDropdown"
        @mouseleave="closeDropdownDelayed"
      >
        <template v-for="(item, index) in menuItems" :key="index">
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click.prevent="item.action">
            {{ item.text }}
          </a>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DropdownMenu',
    props: {
      menuItems: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        showDropdown: false,
        dropdownTimeoutId: null,
      };
    },
    methods: {
      toggleDropdown() {
        this.showDropdown = !this.showDropdown;
      },
      closeDropdownDelayed() {
        if (this.dropdownTimeoutId) {
          clearTimeout(this.dropdownTimeoutId);
        }
        this.dropdownTimeoutId = setTimeout(() => {
          this.showDropdown = false;
        }, 200);
      },
      cancelCloseDropdown() {
        if (this.dropdownTimeoutId) {
          clearTimeout(this.dropdownTimeoutId);
          this.dropdownTimeoutId = null;
        }
      },
    },
  };
  </script>
  