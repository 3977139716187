<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 flex flex-col items-center">
      <div class="mt-4 text-center md:mt-8 w-full md:w-2/3 lg:w-1/2">
        <h1 class="font text-4xl font-bold tracking-tight text-gray-800 dark:text-white sm:text-6xl">{{ $t('welcome') }}</h1>
        <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">{{ $t('home_message') }}</p>
      </div>
      <div class="relative w-full md:w-2/3 lg:w-1/2">
        <img class="w-full dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup.svg" alt="dashboard image">
        <img class="w-full hidden dark:block" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup-dark.svg" alt="dashboard image">
        <div class="plant-svg-container">
          <!-- <img src="@/assets/plant_2.png" alt="Plant 2" class="plant plant2" /> -->
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomePageBanner'
}
</script>

<style scoped>
.plant-svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 80%;
  pointer-events: none;
}

.plant {
  position: absolute;
  animation: float 6s infinite ease-in-out;
}

.plant1 {
  bottom: 15%;
  left: 40%;
  width: 100px;
  height: auto;
  animation-delay: 0s;
}

.plant2 {
  bottom: 5%;
  left: 40%;
  width: 250px;
  height: auto;
  animation-delay: 2s;
}

.plant3 {
  bottom: 15%;
  left: 50%;
  width: 120px;
  height: auto;
  animation-delay: 4s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(100);
  }
  50% {
    transform: translateY(-200px);
  }
}
</style>
