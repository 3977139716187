<template>
  <div>
    <nav :class="['shadow', isDarkMode ? 'bg-gray-800' : 'bg-white', 'border-b', ]">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <router-link v-if="!authenticated" to="/" class="flex-shrink-0">
            
              <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-bold']">Flori</span>
              <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']">bid</span>
              <span class="desktop-only">
                <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
                <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-bold']">ProFlora</span>
              </span>
            </router-link>
            <router-link v-else to="/portal" class="flex-shrink-0">
  <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-bold']">Flori</span>
              <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']">bid</span>
              <span class="desktop-only">
                <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
                <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-bold']">ProFlora</span>
              </span>
            </router-link>
          </div>
          <div class="flex items-center">
            <router-link v-if="!authenticated" to="/" :class="linkClass">Home</router-link>
            <!-- <router-link to="/login" v-if="!authenticated" :class="linkClass">Login</router-link> -->
            <router-link to="/portal" v-if="authenticated" :class="linkClass">{{ $t('navbar_portal') }}</router-link>
            <span v-if="authenticated" :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
            <router-link to="/profile" v-if="authenticated" :class="linkClass">{{ $t('navbar_profile') }}</router-link>
            <span v-if="admin" :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
            <router-link to="/admin" v-if="admin" :class="linkClass">{{ $t('navbar_admin') }}</router-link>
            <span :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
             <div class="dropdown">
              <button class="dropbtn">
                <div :class="linkClass">
                  {{ $t('navbar_language') }}
                </div>
              </button>
              <div class="dropdown-content">
                <a @click="changeLanguage('en')">EN <flag iso="gb" /></a>
                <a @click="changeLanguage('da')">DK <flag iso="dk" /></a>
              </div>
            </div>
            <span  :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
            
            <ConnectionIndicator :isConnected="isConnected" />
            <span  v-if="authenticated" :class="[isDarkMode ? 'text-white' : 'text-gray-900', 'text-xl font-light']"> | </span>
           
            <div>
            <button v-if="authenticated" @click="handleLogout" :class="linkClass">
              <i style="font-size:16px" class="fa mt-1 text-gray-800">&#xf08b;</i> 
            </button>
          </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ConnectionIndicator from './ConnectionIndicator.vue';
import DropdownMenu from './DropdownMenu.vue';
import axios from 'axios';
import router from '@/router';

export default {
  name: 'Navbar',
  components: {
    ConnectionIndicator,
    DropdownMenu,
  },
  data() {
    return {
      isConnected: false,
      auctionInProgress: "",
      username: localStorage.getItem('user'),
      flags: {
        en: 'us',
        da: 'dk',
      },
      currentFlag: "us",
      isDarkMode: false,
    };
  },
  computed: {
    ...mapState(['authenticated', 'admin']),
    linkClass() {
      return `${this.isDarkMode ? 'text-gray-300 hover:bg-gray-300 hover:text-white' : 'text-gray-900 hover:bg-gray-300 hover:text-white'} px-3 py-2 rounded-md text-sm font-medium`;
    }
  },
  methods: {
    ...mapMutations(['setAuthenticated', 'setAdmin']),
    async fetchData() {
      try {
        const response = await axios.get('/ht/?format=json');
        this.isConnected = response.data.DatabaseBackend === 'working';
      } catch (error) {
        console.error(error);
        this.isConnected = false;
      }
    },
    async fetchAuctions() {
      try {
        const accessToken = localStorage.getItem('access_token');
        const response = await axios.get('/get_auction_progress/', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.auctionInProgress = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    handleLogout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      router.push('/');
      this.setAuthenticated(false);
      this.setAdmin(false);
    },
    goToProfile() {
      router.push('/profile');
    },
    goToPurchases() {
      router.push('/purchases');
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.currentFlag = lang;
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    }
  },
  mounted() {
    this.fetchData();
    this.fetchAuctions();
    setInterval(this.fetchData, 20000);
  },
};
</script>

<style scoped>
.border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}

/* Dropdown Button */
.dropbtn {
  color: white;
  padding: 0px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
