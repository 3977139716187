// src/deviceUtils.js
export function isLaptopAndFullScreen() {
  const userAgent = navigator.userAgent;
  
  // Check for common mobile devices user agents
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  
  // Check if the screen width is greater than a typical mobile device width
  const isFullScreen = window.innerWidth >= 1200;
  
  // Return true if it's not a mobile device and the screen is larger than the threshold
  return !isMobile && isFullScreen;
}
