<template>

  <div class="min-h-screen bg-gray-100 p-6">
    <div class="md:flex-row mx-auto px-8 h-full max-w-screen-xl">
      <div class="bg-white rounded-lg shadow-md h-full flex items-center flex-col px-6 py-2">
        <div class="w-full p-4 mb-2 border-b border-gray-200 flex items-center">
          <div class="w-1/4 p-4 overflow-hidden h-full pr-4 mt-4">
            <button @click="goBack" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4">
              <i style="font-size:16px" class="fa mr-2 text-gray-800"> &#xf104;</i>
              <span>Return to portal</span>
            </button>
          </div>
          <div class="w-3/4 p-4 overflow-hidden h-full mx-8 px-4">       
            <p class="text-m"><strong>Auction name: </strong>{{auction_name}}</p>
            <p class="text-m"><strong>Seller: </strong>{{auction_seller}}</p>
            <p class="text-m"><strong>Latency: </strong><i :style="latencyStyle" class="fa">&#xf012;</i></p> 
          </div>
        </div>
        <div class="flex w-full h-800px mt-4 justify-center">
          <div class="w-1/3 p-2 overflow-y-auto h-full pr-4">
            <!-- Top Box with Product Image and Details -->
            <div class="text-sm bg-white p-2 mb-4 rounded-lg border border-gray-200">
              <p class="border-b gray-200 mb-2"><strong>Current Product:</strong>  {{current_product.name}} </p>
              <div class="flex">
                <img :src="current_product.url"  alt="Product Image" class="w-1/2 h-auto rounded-lg mx-4">
                <div class="text-xs text-left ml-2">
                  <p><strong>Grower / seller:</strong> {{current_product.seller}}</p>
                  <p><strong>Length:</strong> {{ current_product.length }}</p>
                  <p><strong>Gram (Weight):</strong>  {{ current_product.weight }} </p>
                  <p><strong>Quality:</strong> {{ current_product.quality }} </p>
                  <p><strong>Stems per unit:</strong> {{ current_product.stems_per_unit }} </p>
                  <p><strong>Quantity in box:</strong> {{ current_product.quantity_in_box }} </p>
                  <p><strong>Start price:</strong> {{ current_product.start_price }} </p>
                  <p><strong>Minimum price:</strong>  {{ current_product.minimum_price }} </p>
                </div>
              </div>
            </div>
            <!-- Bottom Box with Tabs -->
            <div class="bg-white p-2 rounded-lg border border-gray-200">
              <div class="flex justify-center">
                <ul class="flex border-b border-gray-200 mb-4 text-sm">
                  <li class="mr-1">
                    <a :class="['inline-block', 'py-2', 'px-4', 'font-semibold', 'cursor-pointer', {'bg-gray-200': currentTab === 'upcoming', 'bg-white': currentTab !== 'upcoming'}]" @click="showTab('upcoming')">Upcoming Products</a>
                  </li>
                  <li class="mr-1">
                    <a :class="['inline-block', 'py-2', 'px-4', 'font-semibold', 'cursor-pointer', {'bg-gray-200': currentTab === 'purchases', 'bg-white': currentTab !== 'purchases'}]" @click="showTab('purchases')">Purchases</a>
                  </li>
                </ul>
              </div>
              <div v-show="currentTab === 'upcoming'" class="overflow-y-auto h-48">
                <div v-for="product in upcomingProducts" :key="product.id" :class="['flex', 'p-2', 'mb-2', 'mx-4',  'rounded-lg', 'border', 'text-left', 'border-gray-200', {'bg-red-50': product.liked}]">
                  <img :src="product.url" alt="Product Image" class="w-12 h-12 rounded-lg mr-2">
                  <div>
                    <p class="text-sm text-left"><strong>{{ product.name }}</strong></p>
                    <p class="text-xs text-left">Min Price: {{ product.minimum_price }}</p>
                    <p class="text-xs text-left">Start Price: {{ product.start_price }}</p>
                    <p class="text-xs text-left">Min Quantity: {{ product.stems_per_unit }}</p>
                  </div>
                </div>
              </div>
              <div v-show="currentTab === 'purchases'" class="overflow-y-auto h-48">
                <div v-for="purchase in userPurchases" :key="purchase.id" class="flex p-2 mb-2 rounded-lg bg-gray-100 border border-gray-200 mx-4">
                  <img src="https://mediaportal.azurewebsites.net/photos/c68d570e-bef9-4155-af40-893a3b2fce47.jpg" alt="Product Image" class="w-12 h-12 rounded-lg mr-2">
                  <div>
                    <p class="text-sm"><strong>{{ purchase.name }}</strong></p>
                    <p class="text-xs">Quantity: {{ purchase.quantity }}</p>
                    <p class="text-xs">Total Price: {{ purchase.totalPrice }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="w-2/3 justify-center px-4 ">
            <div class="flex  flex-col  h-full">
              <div class="flex-grow mt-12 mx-20" >
              <div class="circle-wrapper">
                <div v-for="i in 101" :key="i" :style="getCircleStyles(i)" :id="'circle-' + i" :class="['circle', 'w-2', 'h-2', 'border', 'border-gray-400', 'rounded-full', 'absolute', {'bg-blue-600': i === circleIdCurr,  'bg-red-300': i === circleIdMin,}]">
                  
                  <!-- <span v-if="i === 10" class="absolute ml-7 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">10</span>
    <span v-if="i === 20" class="absolute ml-6 mt-1 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">20</span>
    <span v-if="i === 30" class="absolute ml-10 mt-1 top-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600" style="position: relative; right: 25px;">30</span>
    <span v-if="i === 40" class="absolute ml-4 mt-7 top-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">40</span> -->
    <span v-if="i === 50" class="absolute mt-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">50</span>
    <!-- <span v-if="i === 60" class="absolute mr-4 mt-7 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">60</span>
    <span v-if="i === 70" class="absolute mt-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600" style="position: relative; right: 25px;">70</span>
    <span v-if="i === 80" class="absolute mt-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600" style="position: relative; right: 15px;">80</span>
    <span v-if="i === 90" class="absolute mt-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600" style="position: relative; right: 15px;">90</span> -->
    <span v-if="i === 100" class="absolute pb-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">0</span>
<!-- <span v-if="i % 10 === 0" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600">{{ i }}</span> -->
                </div>
              </div>
            </div>
            <!-- New horizontal box -->
            <div class="w-1/2 text-sm bg-white mx-24 p-2 rounded-lg border border-gray-200" style="position: relative; top: -12px; left: 30px;">
              <p class="border-b gray-200 mb-2"><strong>Current product</strong></p>
              <p class="text-xs"><strong>Current price:</strong> {{ current_price }} | Quantity: {{ remaining_quantity }} </p>
             
            </div>
            <!-- <div class="w-1/2 text-sm bg-white mx-24 p-2 rounded-lg border border-gray-200" style="position: relative; top: -12px; left: 30px;">
              <p class="border-b gray-200 mb-2"><strong>Previous transaction</strong></p>
              <p class="text-xs"><strong>Product:</strong> n/a | Quantity: n/a | Price: n/a</p>
             
            </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="count_down_seconds > 0 && current_product === 0" class="overlay">
      <div class="timer">
        <span>{{ count_down_seconds }} seconds until the auction starts </span>
      </div>
    </div>

  </template>
  
 
<script>
// @ is an alias to /src
export default {
  name: 'AuctionView',
  components: {
   auctionId: "",
  },
  data() {
    return {
      auction_name:"",
      auction_seller:"",
      serverTimeStamp: null,
      clientTimestamp: null,
      latency: 0,
      latencyThreshold: 0.3,
      latencyCheckInterval: null,
      showingSnackbar: false,
      showingSnackbarBuyNotify: false,
      snackbarText: '',
      products: [],
      original_price: null,
      current_price: null,
       clock_price: 0,
      minimum_price: null,
      remaining_quantity: 0,
      circleIdMin: 99,
      circleIdCurr: 100,
      begin_time: null,
      count_down_seconds: 0,
      clock_end_time: null,
      clockInterval: null,
      current_product: 0,
      clock_title: "Auction clock",
      clockIntervals: [], // Reset the array
      currentFlowerName: "",
     lateJoiner:false,
     endOfAuction: false,
     
     username: localStorage.getItem('user'),
     currentTab: 'upcoming',
      upcomingProducts: [
        // Example upcoming products
       
        // Add more products as needed
      ],
      userPurchases: [

      ]
    }
  },
    computed: {
      latencyStyle() {
      if (this.latency <= 0.15) {
        return { color: 'green' };
      } else if (this.latency <= 0.3) {
        return { color: 'yellow' };
      } else {
        return { color: 'red' };
      }
    },
    totalProducts() {
      return this.products.length || 0;
    },
    // progressPercentage() {
    //   return (this.current_product / (this.totalProducts || 1)) * 100;
    // },
    nextProduct() {
      return this.products[this.current_product_id + 1] || { name: "No more products" }; 
    },
    // remainingQuantityPercentage() {
    //     if (this.products[this.current_product].remaining_quantity) {
    //         return ((this.remaining_quantity/ this.products[this.current_product].remaining_quantity)) * 100;
    //     }
    //     return 0;
// } ,
   
shouldDisableButton() {
    return (multiplier) => {
      try {
        const currentProduct = this.products[this.current_product];
        if (!currentProduct) {
          return true; // Disable the button if the current product is not found
        }
        if (this.begin_time === null) {
      return true;
    }
    if (this.count_down_seconds > 0){
        return true
      }
      if (multiplier === 0){
        return false
      }
        const requiredQuantity = currentProduct.minimum_quantity * multiplier;
        if (this.remaining_quantity){
             return requiredQuantity > this.remaining_quantity;
        }
       else {
        return true;
       }
      } catch (error) {
        if (error instanceof TypeError) {
          return true; // Disable the button if there's a TypeError
        }
        throw error; // If it's not a TypeError, rethrow it
      }
    };
  },

  isJoiningLate(){
    if (this.begin_time === null) {
        return false;
    }
    return new Date() < this.begin_time;
  }
   
},
created(){
  this.checkJoiner();
  this.username = localStorage.getItem('user');
},
  mounted() {
    this.checkLatency(); // execute once first then create the hook
    this.startLatencyCheck(); 
    window.addEventListener('keydown', this.handleKeyDown);
    const au_id = this.$route.query.id
    this.ws = new WebSocket(`wss://flowers.nu/liveauction/${au_id}/`);
    this.ws.onopen = () => {
      console.log('WebSocket connection opened');
      
    };
    
    this.ws.onmessage = (event) => {
      console.log('Received message:', event.data);
      const data = JSON.parse(event.data);

       if (data.action === 'auction_products') {
        this.products = data.data.products;
        this.upcomingProducts = data.data.products
        this.auction_name = data.data.auction_name,
        this.auction_seller= data.data.auction_seller,
        this.current_product_id = data.data.current_product
        this.current_product = this.products[this.current_product_id];
        this.remaining_quantity = data.data.total_quantity;
        // Find the index of the current product
        const index = this.upcomingProducts.indexOf(this.current_product);
        // Check if the product exists in the array and remove it
          if (index > -1) {
              this.upcomingProducts.splice(index, 1);
          }

        this.setAuctionVariables(this.current_product_id)
            }

    if (data.action === 'buy_notification') {
      this.stopClock();
      this.remaining_quantity = data.data.remaining_quantity
      
      if (this.remaining_quantity === 0){
          this.current_price = this.minimum_price
          this.clock_price = this.minimum_price
          this.circleIdCurr = this.circleIdMin + 1
          this.sendStartNextMessage()
        }
      else {
        this.begin_time = this.parseDate(data.data.begin_time)
        this.clock_end_time = this.parseDate(data.data.clock_end_time);
        this.runClock();
      }

   

      if (this.showingSnackbar===false){
     
        if (this.username === data.data.buyer){
     
          qnty = data.data.pcs_bought
      this.snackbarText = qnty + 'pcs bought!'
      this.showingSnackbar = true
      setTimeout(() => {
        this.showingSnackbar = false
      }, 2000); }
    
    else {

      this.showingSnackbarBuyNotify = true
      setTimeout(() => {
        this.showingSnackbarBuyNotify = false
      }, 2000)};    }}

    if (data.action === "end_notification"){
      // this.endOfAuction = true;
      this.stopClock();
      this.circleIdCurr = 100;
      this.circleIdMin = 99;
      this.remaining_quantity = 0;
      this.count_down_seconds = null;
      this.clockIntervals.forEach(intervalId => {
        clearInterval(intervalId);
      });
      // Reset the intervals array
      this.clockIntervals = [];

      setTimeout(()=>{this.$router.push('/portal');}, 5000); 
    }
    if (data.action === 'start_notification') {
      this.current_product_id = data.data.current_product
        this.current_product = this.products[this.current_product_id];

        // Find the index of the current product
        const index = this.upcomingProducts.indexOf(this.current_product);
        // Check if the product exists in the array and remove it
          if (index > -1) {
              this.upcomingProducts.splice(index, 1);
          }
      // this.remaining_quantity = this.products[data.data.current_product].remaining_quantity
      this.currentFlowerName = this.products[data.data.current_product].name
      this.minimum_price = this.products[data.data.current_product].minimum_price
      this.circleIdMin = Math.round(((this.minimum_price / this.original_price) * 100 ))
      
      this.current_price = this.products[data.data.current_product].start_price
      this.clock_price = this.current_price
      this.original_price = this.products[data.data.current_product].start_price
      
      this.begin_time = this.parseDate(data.data.begin_time)
      this.clock_end_time = this.parseDate(data.data.clock_end_time)

      const timeDiff = this.begin_time.getTime() - Date.now();
      this.count_down_seconds = Math.floor(timeDiff / 1000);
      
      let intervalId = setInterval(() => {
        if (this.count_down_seconds === 0) {
            clearInterval(intervalId);
            this.runClock();
      }
      else{
      const timeDiff = this.begin_time.getTime() - Date.now();
      this.count_down_seconds = Math.floor(timeDiff / 1000);
    } }, 100);}},
    this.clockIntervals.push(intervalId);
    this.ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
    
    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };  
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
    beforeDestroy() {
    // Clear all intervals
    this.clockIntervals.forEach(intervalId => {
      clearInterval(intervalId);
    });
    // Reset the intervals array
    this.clockIntervals = [];
    if (this.latencyCheckInterval) {
      clearInterval(this.latencyCheckInterval);
    }
  },
  methods: {
     startLatencyCheck() {
      this.latencyCheckInterval = setInterval(this.checkLatency, 10000);
    },
    async checkLatency() {
      
      try {
        const response = await fetch('https://flowers.nu/api/v1/latency/');
        const data = await response.json();
        const clientTimestamp = Date.now() / 1000;
        const serverTimestamp = data.server_time;
        this.latency = clientTimestamp - serverTimestamp;
      } catch (error) {
        console.error('Failed to fetch latency data:', error);
        this.latency = 1
      }
    },
    showTab(tab) {
        this.currentTab = tab;
      },
    goBack() {
            this.$router.push('/portal');
        },
handleKeyDown(event) {
  try {
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      const buttonValue = event.keyCode - 48;
      this.showSnackbar(buttonValue);
    } else if (event.keyCode === 83) {
      this.sendStartMessage();
    }
  } catch (error) {
    console.error(`Error handling keydown event: ${error.message}`);
  }
},
runClock() {

const numberOfSteps = 100;
    const differencePerStep = (this.original_price - this.minimum_price) / (numberOfSteps);
    const now = Date.now();
    const timeDiff = this.clock_end_time.getTime() - this.begin_time.getTime();
    const timePerStep = timeDiff / numberOfSteps;
    const delay = Math.max(0, this.begin_time.getTime() - now);
    this.circleIdCurr = Math.round(((this.current_price / this.original_price) * 100 ))
    this.circleIdMin = Math.round(((this.minimum_price / this.original_price) * 100 ))

    setTimeout(() => {}, delay);

    this.clockIntervals.push(setInterval(() => {
      if (this.count_down_seconds > 0) {
        this.stopClock();// Clearing the interval to stop the clock
            this.circleIdCurr = 100;
            this.circleIdMin = 99;
            return;
  }

      const newPrice = this.current_price - differencePerStep;
      if (newPrice >= this.minimum_price) {
        const prevCurrentPrice = this.current_price;  // Step 1: Storing previous value
       // Step 2: Attempt the update
       this.current_price = newPrice;//.toFixed(3);
       this.clock_price = newPrice.toFixed(2)
       this.circleIdCurr = Math.round(((this.current_price / this.original_price) * 100 ));
       // Step 3: Check if variables were updated
       if (prevCurrentPrice == newPrice) {
           // If the value didn't update, forcefully update or reset
           this.current_price = "";  // Resetting for demonstration; set to appropriate value
           this.current_price = newPrice;//.toFixed(3);  // Attempting the update again

           this.circleIdCurr = "";  // Resetting for demonstration; set to appropriate value
           this.circleIdCurr = Math.round(((this.current_price / this.original_price) * 100 ));
        } }
        else if (newPrice <= this.minimum_price)  {
            this.stopClock();// Clearing the interval to stop the clock
            this.circleIdCurr = 100;
            this.circleIdMin = 99;
            this.sendStartNextMessage();
            }
    }, timePerStep));
},
    stopClock(){
      this.clockIntervals.forEach(id => clearInterval(id));
      this.clockIntervals = []; // Reset the array
      this.circleIdCurr = 100;
      this.circleIdMin = 99;
      // clearInterval(this.clockInterval);
      this.current_price = this.original_price;
      this.clock_price = this.original_price
    },
    setAuctionVariables(current_product){
       this.original_price = this.products[current_product].start_price
        this.current_price = this.products[current_product].start_price
        this.minimum_price = this.products[current_product].minimum_price
        // this.remaining_quantity = this.products[current_product].remaining_quantity
        this.currentFlowerName = this.products[current_product].name
    },
    parseDate(dateString){
      const dateParts =  dateString.split(/[\s/:]+/); // split by spaces, colons, and slashes
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${dateParts[3]}:${dateParts[4]}:${dateParts[5]}`;
      const dateFormat = new Date(formattedDate);
      return dateFormat
    },
    sendMessage(test, qnty, price){
      const message = test
      var username = localStorage.getItem('user');
      this.ws.send(`{"message":${message}, "user":"${username}", "quantity":"${qnty}", "price":"${price}", "action":"buy"}`) 
    },
    sendStartMessage(){
      const message = 1 
      this.ws.send(`{"message":${message}, "action":"start"}`) 
    },
    sendStartNextMessage(){
      const message = 1 
      this.ws.send(`{"message":${message}, "action":"next_item"}`) 
    },

    showSnackbar(value) {     
    let qnty 
    if (typeof value === "number") {
       qnty = value *  this.products[this.current_product_id].stems_per_unit
    }
    else {
       qnty = this.remaining_quantity
    }

    if (qnty > this.remaining_quantity) {
        qnty = this.remaining_quantity
       }
      this.remaining_quantity -= qnty  
      this.sendMessage(this.remaining_quantity, qnty, this.current_price);
      this.snackbarText = qnty + 'pcs bought!'
      this.showingSnackbar = true
      setTimeout(() => {
        this.showingSnackbar = false
      }, 2000)
    },
  

      getCircleStyles(index) {
      const div = 360 / 100 ; // divide the circle into 6 segments
      const radius = 170; // set the radius of the larger circle
      const offsetToParentCenter = 130; // set the offset to the center of the parent div
      const offsetToChildCenter = -0; // set the offset to the center of the child circles
      const totalOffset = offsetToParentCenter - offsetToChildCenter; // calculate the total offset

      // use trigonometry to calculate the position of the current circle on the larger circle
      const y = Math.sin((div * index+270) * (Math.PI / 180)) * radius;
      const x = Math.cos((div * index+270) * (Math.PI / 180)) * radius;

      // return an object with styles to position the current circle
      return {
        top: `${y + totalOffset+ 10}px`,
        left: `${x + totalOffset+ 120}px`,
      };
    },
    checkJoiner(){
  const intervalId = setInterval(() => {
this.lateJoiner = this.isJoiningLate;

if (!this.isJoiningLate){
  clearInterval(intervalId);
  this.lateJoiner = false;
} }, 100)  }
 ,
  }
}
</script>


<style scoped>

/* Media query for screens smaller than 600px wide */
@media (max-width: 500px) {
  .circle-wrapper {
    position: relative; 
    width: 100px; /* Adjusted width for smaller screens */
    height: 60px; /* Adjusted height for smaller screens */
  }
}

.circle-wrapper {
  position: relative;
  /* width: 300px; */
  height: 300px;
}
.circle {
  position: absolute; /* Set child divs position to absolute */
  width: 8px;
  height: 8px;
  border-radius: 50%;

  background-color: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #fff;
  font-size: 3rem;
}

.timer {
  color: #fff;
  font-size: 3rem;
}


/* Styling for progress bar */
.progress-bar-container {
  width: 100%;
  background: #e4e4e4;
  height: 10px;
  border-radius: 10px;
}
.progress-bar-container-mobile {
  width: 50%;
  background: #e4e4e4;
  height: 10px;
  border-radius: 10px;
}
.progress-bar-fill {
  background: rgb(32, 34, 32);
  height: 10px;
  border-radius: 10px;
}

.quantity-container {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center;     /* Vertical centering */
  margin-left: 10rem;
  margin-top: 1rem;
  background-color: #E5E7EB; /* bg-gray-300 */
  border-radius: 10px; /* rounded-lg */
  width: 25%;
  height: 10px; /* h-4 */
  position: relative; /* Needed to position the child element absolutely inside this container */
}

.quantity-fill {
    position: absolute;
    left: 0;
    bottom: 0;  /* This ensures the fill starts from the bottom */
    height: 10px;
    border-radius: 10px;
    background-color:rgb(74 222 128); /* bg-green-400 */
}

</style>
