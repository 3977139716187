<template>
    
<div class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="w-full max-w-md space-y-8">
      <div>

        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign-in to the auction portal</h2>
       
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="login">
        <input type="hidden" name="remember" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <label for="username" class="sr-only">Username</label>
            <input id="username" name="username" type="text" autocomplete="username" required="" class="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6" placeholder="Username" v-model="username" />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" name="password" type="password" autocomplete="current-password" required="" class="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6" placeholder="Password" v-model="password"/>
          </div>
        </div>

        <div>
          <button type="submit" class="group relative flex w-full justify-center rounded-md bg-gray-800 py-2 px-3 text-sm font-semibold text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-gray-500 group-hover:text-indigo-400" aria-hidden="true" />
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
     <div v-if="errors.length" class="fixed bottom-0 left-0 right-0 z-50 p-4">
    <div class="bg-red-500 text-white font-semibold rounded-md shadow-md py-2 px-4">
       <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
  </div>

     <!-- <div class="notification is-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
      </div> -->
  </div>
  
  </template>

  
<script>
import axios from 'axios'
import { mapMutations  } from 'vuex'

export default {
  name: 'Login',
   data() {
    return {
      username: '',
      password: '',
      errors: [],
    }
  },
  methods: {
    ...mapMutations(['setAuthenticated']),
    ...mapMutations(['setAdmin']),
    async adminCheck(){
  const accessToken = localStorage.getItem('access_token');
  const response = await axios.get('/admin_rights/',  {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  if (response.data.is_admin === true){
    this.setAdmin(true) 
  }

},
    async login() {
      axios.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")

      try {
        const response = await axios.post('/token/', {
          username: this.username,
          password: this.password,
        })
        localStorage.setItem('access_token', response.data.access)
        localStorage.setItem('refresh_token', response.data.refresh)
        localStorage.setItem('user', this.username)
        this.setAuthenticated(true);
        this.adminCheck();


        const toPath = this.$route.query.to || "/portal"
        this.$router.push(toPath) 
      } catch (error) {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                            
                        } else if (error.message) {
                            this.errors = []
                            this.errors.push('Something went wrong. Please try again')
                            console.log(JSON.stringify(error))
                        }
                 
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.errors = []
      }, 3000)
      
      
    
  };
    },   

}, 

}
</script>