<template>
  <div class="min-h-screen bg-gray-100 p-6">
    <div class="flex flex-col md:flex-row mx-auto px-4 h-full max-w-screen-xl">
      <!-- Profile Settings -->
      <div class="w-full md:w-1/3 p-4">
        <div class="bg-white rounded-lg shadow-md h-full flex flex-col p-6">
          <div class="pb-4 border-b border-gray-200">
            <h1 class="text-lg font-semibold">Profile settings</h1>
            <p class="text-sm text-gray-500">Update your password here.</p>
          </div>
          <div class="pt-4 flex-grow overflow-y-auto no-scrollbar">
            <div class="mb-4">
              <label class="text-gray-700 block mb-1">User Name</label>
              <span class="block px-3 py-2 bg-gray-100 rounded">{{ user.username }}</span>
            </div>

            <div class="mb-4">
              <label class="text-gray-700 block mb-1">Current password</label>
              <input v-model="currentPassword" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white text-black">
            </div>
            <div class="mb-4">
              <label class="text-gray-700 block mb-1">New password</label>
              <input v-model="newPassword" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white text-black">
            </div>
            <div class="mb-4">
              <label class="text-gray-700 block mb-1">Confirm password</label>
              <input v-model="confirmPassword" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white text-black">
            </div>
            <div class="mt-6 flex justify-center">
              <button @click="updatePassword" class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Save</button>
            </div>
          </div>
        </div>
      </div>
<!-- Purchase Overview -->
<div class="w-full md:w-2/3 p-4 mt-6 md:mt-0">
  <div class="bg-white rounded-lg shadow-md h-full flex flex-col p-6">
    <div class="pb-4 border-b border-gray-200">
      <h1 class="text-lg font-semibold">Purchase Overview</h1>
      <p class="text-sm text-gray-500">Look at the items you bought at the auction, download an overview, or dispute mistakes.</p>
    </div>
    <div class="flex-grow flex overflow-hidden mt-4">
      <!-- Auction List -->
      <div class="w-full md:w-1/4 p-4 border-r border-gray-200 overflow-y-auto no-scrollbar">
        <ul>
          <li v-for="auctionName in auctionNames" :key="auctionName" @click="toggleAuctionSelection(auctionName)" class="p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100" :class="{'bg-gray-200': selectedAuction === auctionName}">
            <div class="font-semibold text-sm">{{ auctionName }}</div>
            <div class="text-gray-500 text-sm">{{ getAuctionDate(auctionName) }}</div>
          </li>
        </ul>
      </div>
      <!-- Purchases for Selected Auction -->
      <div class="w-full md:w-3/4 p-4 overflow-y-auto no-scrollbar">
        <div v-if="isLoading" class="text-center p-4">Loading...</div>
        <div v-if="error" class="text-red-500 text-center p-4">{{ error }}</div>
        <div v-if="selectedAuction">
          <div class="overflow-x-auto mb-10">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product ID</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                  <th class="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="purchase in filteredPurchases" :key="purchase.id" class="hover:bg-gray-50">
                  <td class="px-4 py-2 text-sm text-gray-900">{{ purchase.product.id }}</td>
                  <td class="px-4 py-2 text-sm text-gray-900"> {{ purchase.product.name }} </td>
                  <td class="px-4 py-2 text-sm text-gray-900">{{ purchase.quantity }}</td>
                  <td class="px-4 py-2 text-sm text-gray-900">{{ purchase.unit_price }}</td>
                  <td class="px-4 py-2 text-right text-sm">
                    <button @click="openDisputeModal(purchase)" class="text-blue-500 hover:underline">Dispute</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <p>Select an auction to view details.</p>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>


    <!-- Dispute Modal -->
    <div v-if="isDisputeModalOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg shadow-md w-1/3">
        <h3 class="text-lg font-semibold mb-4">Dispute Purchase</h3>
        <p class="mb-2">Disputing purchase ID: {{ disputeItem.product.id }}</p>
        <textarea v-model="disputeText" class="w-full p-2 border border-gray-300 rounded-lg mb-4" rows="4" placeholder="Describe your issue"></textarea>
        <div class="flex justify-end">
          <button @click="closeDisputeModal" class="px-4 py-2 bg-gray-500 text-white rounded-lg mr-2">Cancel</button>
          <button @click="sendDispute" class="px-4 py-2 bg-blue-500 text-white rounded-lg">Send Dispute</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'ProfileView',

  data() {
    return {
      user: {
        username: '',
        email: ''
      },
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      purchases: [],
      auctionPurchases: {},
      auctionNames: [],
      selectedAuction: null,
      isLoading: false,
      error: null,
      searchQuery: '',
      showActions: null,
      isDisputeModalOpen: false,
      disputeItem: null,
      disputeText: '',
     
    };
  },
  methods: {
    fetchUserData() {
     
      const userString = localStorage.getItem('user');
      this.user = {
        username: userString,
        // email: 'john.doe@example.com'
      };
    },
    fetchPurchases() {
      this.isLoading = true;
      const accessToken = localStorage.getItem('access_token');
      axios.get('/purchases/', { // Replace '/purchases/' with your actual API endpoint
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        this.purchases = response.data;
        this.processPurchases();
      })
      .catch(error => {
        this.error = "Failed to load purchases.";
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    processPurchases() {
      this.auctionPurchases = this.purchases.reduce((acc, purchase) => {
        const auctionName = purchase.auction.name;
        if (!acc[auctionName]) {
          acc[auctionName] = [];
        }
        acc[auctionName].push(purchase);
        return acc;
      }, {});

      this.auctionNames = Object.keys(this.auctionPurchases);
    },
    selectAuction(auctionName) {
      this.selectedAuction = auctionName;
    },
    toggleAuctionSelection(auctionName) {
      this.selectedAuction = this.selectedAuction === auctionName ? null : auctionName;
    },
    getAuctionDate(auctionName) {
      const auction = this.auctionPurchases[auctionName] && this.auctionPurchases[auctionName][0];
    if (auction && auction.purchase_time) {
      const date = new Date(auction.purchase_time);
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(date);
    }
    return '';

      // const auction = this.auctionPurchases[auctionName][0];
      // return auction ? auction.purchase_time : '';
    },
    updatePassword() {
      if (this.newPassword !== this.confirmPassword) {
        alert('Passwords do not match');
        return;
      }
      // Replace with actual API call
      alert('Password updated successfully');
    },
    toggleActions(purchaseId) {
      this.showActions = this.showActions === purchaseId ? null : purchaseId;
    },
    openDisputeModal(purchase) {
      this.disputeItem = purchase;
      this.isDisputeModalOpen = true;
    },
    closeDisputeModal() {
      this.isDisputeModalOpen = false;
      this.disputeText = '';
    },
    sendDispute() {
      // Replace with actual API call to send dispute
      console.log('Dispute sent for', this.disputeItem);
      console.log('Dispute Text:', this.disputeText);
      this.closeDisputeModal();
      alert('Dispute sent successfully');
    }
  },
  computed: {
    filteredPurchases() {
      if (!this.selectedAuction) return [];
      const purchases = this.auctionPurchases[this.selectedAuction];
      if (!this.searchQuery) return purchases;
      const query = this.searchQuery.toLowerCase();
      return purchases.filter(purchase => 
        purchase.product.id.toLowerCase().includes(query) || 
        purchase.quantity.toString().includes(query) ||
        purchase.price.toString().includes(query)
      );
    }
  },
  mounted() {
    this.fetchUserData();
    this.fetchPurchases();
  }
}
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
.overflow-hidden {
  overflow: hidden;
}

.justify-center {
  justify-content: center;
}

.overflow-x-auto {
  overflow-x: auto;
}

.flex {
  display: flex;
}


.overflow-x-auto {
  overflow-x: auto;
}


.items-center {
  align-items: center;
}

.space-x-4 > * + * {
  margin-left: 1rem; /* Adjust spacing as needed */
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.w-64 {
  width: 16rem; /* Width of each box */
}

.h-40 {
  height: 10rem; /* Height of each box */
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
